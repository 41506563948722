export const state = () => ({
  cv07: null,
})

export const getters = {
  experiments: (state) => state,
}

export const mutations = {
  setExperiment: (state, [experimentId, enabled]) => {
    state[experimentId] = enabled
  },
}
